<template>
  <div>
    <div class="list-wrapper" v-if="articles.length !== 0">
      <div class="list-head">
        <h3 class="list-title label-middle-description bold">
          뉴스
          <span class="count">{{newsCount}}</span>
        </h3>

        <button class="button button-all-remove button-arrow middle" @click="moreNews()">
          뉴스 더 보기
          <span class="icon-arrow">
            <span class="icon"></span>
          </span>
        </button>
      </div>

      <ul class="list short-top news">
        <li class="item" v-for="article in articles">
          <article class="item-wrapper investing-item">
            <div class="image-wrapper remove-line-height">
              <a :href="articleUrl(article)" @click="clickArticle(article)">
                <div class="image-box" :style="heroImageUrl(article)">
                  <img class='blank-image' src="https://assets.themiilk.com/common/blank-6-4.png" alt='' />
                </div>
              </a>
            </div>

            <div class="text-wrapper">
              <ul class="tags" v-if="article.tags.length > 0">
                <li class="tag" v-for="tag in article.tags">
                  <a :href="tagUrl(tag)">
                    {{ tag.name }}
                  </a>
                </li>
              </ul>

              <a :href="articleUrl(article)" @click="clickArticle(article)">
                <h2 class="title label-middle-title bold ellipsis one">
                  {{ article.title }}
                </h2>
              </a>

              <a class="no-deco" :href="articleUrl(article)" v-if="article.first_text" @click="clickArticle(article)">
                <p class="description label-small-description ellipsis three">
                  {{ stripTag(article.first_text) }}
                </p>
              </a>

              <p class="author label-xsmall">
                <!-- <span class="bold">{{ article.author_names }}</span> -->
                <author-name :article="article" />
                <span class="date">
                  {{ article.str_published_at }}
                </span>
              </p>
            </div>
          </article>
        </li>
      </ul>
    </div>

    <div class="list-wrapper" v-if="videos.length !== 0">
      <div class="list-head">
        <h3 class="list-title label-middle-description bold">
          비디오
          <span class="count">{{videoCount}}</span>
        </h3>

        <button class="button button-all-remove button-arrow middle" @click="moreVideo()">
          비디오 더 보기
          <span class="icon-arrow">
            <span class="icon"></span>
          </span>
        </button>
      </div>

      <ul class="list short-top videos">
        <li class="item" v-for="video in videos">
          <article class="item-wrapper investing-item">
            <div class="image-wrapper remove-line-height">
              <a :href="videoLink(video)" @click="clickVideo(video)">
                <div class="image-box" :style="videoHeroImageUrl(video)">
                  <img class='blank-image' src="https://assets.themiilk.com/common/blank.png" alt='' />
                </div>
              </a>
            </div>

            <div class="text-wrapper">
              <a :href="videoLink(video)" @click="clickVideo(video)">
                <h2 class="title label-middle-title bold ellipsis one">
                  {{ video.title }}
                </h2>
              </a>

              <a class="no-deco" :href="videoLink(video)" v-if="video.description" @click="clickVideo(video)">
                <p class="description label-small-description ellipsis three">
                  {{ video.description }}
                </p>
              </a>

              <p class="statistics label-xsmall">
                <span class="bold">{{ video.str_published_at }}</span>
                <span class="count">
                  조회수 {{ video.view_count }}
                </span>
              </p>
            </div>
          </article>
        </li>
      </ul>
    </div>

    <div class="list-wrapper" v-if="reports.length !== 0">
      <div class="list-head">
        <h3 class="list-title label-middle-description bold">
          리포트
          <span class="count">{{reportCount}}</span>
        </h3>

        <button class="button button-all-remove button-arrow middle" @click="moreReport()">
          리포트 더 보기
          <span class="icon-arrow">
            <span class="icon"></span>
          </span>
        </button>
      </div>

      <ul class="list short-top news">
        <li class="item" v-for="report in reports">
          <article class="item-wrapper investing-item">
            <div class="image-wrapper remove-line-height">
              <a :href="reportUrl(report)" @click="clickReport(report)">
                <div class="image-box" :style="reportHeroImageUrl(report)">
                  <img class='blank-image' src="https://assets.themiilk.com/common/blank-6-4.png" alt='' />
                </div>
              </a>
            </div>

            <div class="text-wrapper">
              <ul class="tags" v-if="report.tags.length > 0">
                <li class="tag" v-for="tag in report.tags">
                  <a :href="tagUrl(tag)">
                    {{ tag.name }}
                  </a>
                </li>
              </ul>

              <a :href="reportUrl(report)"  @click="clickReport(report)">
                <h2 class="title label-middle-title bold ellipsis one">
                  {{ removeTag(report.title) }}
                </h2>
              </a>

              <a class="no-deco" :href="reportUrl(report)" v-if="report.summary"  @click="clickReport(report)">
                <p class="description label-small-description ellipsis three">
                  {{ stripTag(removeTag(report.summary)) }}
                </p>
              </a>

              <p class="author label-xsmall">
                <span class="bold">발행일</span>
                <span class="date">
                  {{ publishedAt(report.published_at) }}
                </span>
              </p>
            </div>
          </article>
        </li>
      </ul>
    </div>

    <div class="list-wrapper" v-if="the100.length !== 0">
      <div class="section-container">
        <div class="section-head">
          <h3 class="section-title bold">
            미국형님
          </h3>
        </div>

        <p class="section-description label-small-description">
          정확한 정보와 데이터, 깊이 있는 분석을 바탕으로 성장 가능성 높은 회사를 선정했습니다. <span class="point bold">{{query}}</span>와(과) 관련된 회사들을 살펴보세요.
        </p>
      </div>

      <ul class="list short-top news" v-if="isYearlyPlan">
        <li class="item" v-for="article in the100">
          <article class="item-wrapper investing-item">
            <div class="image-wrapper remove-line-height">
              <a :href="articleUrl(article)">
                <div class="image-box" :style="heroImageUrl(article)">
                  <img class='blank-image' src="https://assets.themiilk.com/common/blank-6-4.png" alt='' />
                </div>
              </a>
            </div>

            <div class="text-wrapper">
              <ul class="tags" v-if="article.tags.length > 0">
                <li class="tag" v-for="tag in article.tags">
                  <a :href="tagUrl(tag)">
                    {{ tag.name }}
                  </a>
                </li>
              </ul>

              <a :href="articleUrl(article)" @click="clickArticle(article)">
                <h2 class="title label-middle-title bold ellipsis one">
                  {{ article.title }}
                </h2>
              </a>

              <a class="no-deco" :href="articleUrl(article)" v-if="article.first_text" @click="clickArticle(article)">
                <p class="description label-small-description ellipsis three">
                  {{ stripTag(article.first_text) }}
                </p>
              </a>

              <p class="author label-xsmall">
                <span class="bold">{{ article.author_names }}</span>
                <span class="date">
                  {{ article.str_published_at }}
                </span>
              </p>
            </div>
          </article>
        </li>
      </ul>

      <div class="empty" v-if="!isYearlyPlan">
        <h2 class="point">연간 회원에게만 제공 됩니다.</h2>

        <div class="button-wrapper">
          <a href="/orders/plans" class="button-sign-up button button-box green no-deco" @click="clickYearChange">
            연간플랜으로 전환하기
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthorName from "../commons/components/author_name.vue";

  export default {
    props: 
      ['query','articles','newsCount','videos','videoCount','reports','reportCount','the100','isYearlyPlan'],
    data() {
      return {}
    },
    mounted() {},
    methods: {
      heroImageUrl: function(article) {
        const imageUrl = "https://s3.ap-northeast-2.amazonaws.com/image.themiilk.com/";
        return `background-size: cover; background-image: url('${imageUrl}${article.hero_image_url}')`;
      },
      reportHeroImageUrl: function(article) {
        const imageUrl = "https://s3.ap-northeast-2.amazonaws.com/image.themiilk.com/";
        return `background-size: cover; background-image: url('${article.main_image_url}')`;
      },
      articleUrl: function(article) {
        return `/articles/${article.nickname}`;
      },
      clickArticle: function(article) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'click_article',
          'article_id': article.id,
          'article_title': article.title,
          'article_author': article.author,
          'article_published_at': article.published_at
        })
      },
      reportUrl: function(report) {
        return `/reports/${report.id}`
      },
      clickReport: function(report) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'click_report',
          'report_id': report.id,
          'report_title': report.title,
          'report_published_at': report.published_at
        })
      },
      isFree: function(article) {
        if (article.free_start_at == null || article.free_end_at == null) {
          return false;
        }

        var date = new Date();
        var startAt = new Date(article.free_start_at);
        var endAt = new Date(article.free_end_at);

        return ((startAt <= date) && (endAt > date));
      },
      stripTag: function(str) {
        var div = document.createElement("div");
        div.innerHTML = str;
        var text = div.textContent || div.innerText || "";
        return text;
      },
      tagUrl: function(tag) {
        return `/tags/${tag.id}`;
      },
      moreNews: function() {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'click_more',
          'category': 'news'
        })

        location.href = `/search?q=${this.query}&type=news&sort=acc`
      },
      moreVideo: function() {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'click_more',
          'category': 'video'
        })

        location.href = `/search?q=${this.query}&type=video&sort=new`;
      },
      moreReport: function() {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'click_more',
          'category': 'report'
        })

        location.href = `/search?q=${this.query}&type=reports&sort=new`;
      },
      videoLink: function(video) {
        return `/videos/${video.id}`;
      },
      clickVideo: function(video) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'click_video',
          'video_id': video.id,
          'video_title': video.title,
          'video_author': video.author,
          'video_published_at': video.published_at
        })
      },
      clickYearChange: function() {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'click_year_change',
          'category': 'report'
        })

      },
      videoHeroImageUrl: function(video) {
        return `background-size: cover; background-image: url('${video.thumbnail_url}')`;
      },
      removeTag (oriText) {
        return oriText.replace(/(<([^>]+)>)/ig,"");
      },
      stripTag: function(str) {
        var div = document.createElement("div");
        div.innerHTML = str;
        var text = div.textContent || div.innerText || "";
        return text;
      },
      publishedAt: function(date) {
        date = new Date(date);
        date.setHours(date.getHours() + 9);
        return date
          .toISOString()
          .replace("T", " ")
          .substring(0, 10);
      },
    },
    components: {
      AuthorName
    }
  }
</script>